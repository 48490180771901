.header{
    background-color: transparent;
    position: fixed;
    display: none;
    opacity: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    z-index: 9999;




    .background{
        background-color: black;
        opacity: 0.5;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 9999;
    }

    .bar{
        display: flex;
        justify-content: end;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
        position: relative;

        p{
            margin: 0px;
        }

        a{
            text-decoration: none;
            color: black;
            position: absolute;
            left: 0;
        }

        .shopContainer{
            display: flex;
            gap: 30px;
            position: absolute;
            right: 0;

            .shop{
                display: none;
            }
        }

        .el{
            display: flex;
            align-items: center;
            
            gap: 8px;
            cursor: pointer;

            .label{
                position: relative;
                display: flex;
                align-items: center;
            }

            p{
                &:nth-of-type(2){
                    position: absolute;
                    opacity: 0;
                }
            }

            .burger{
                width: 22.5px;
                position: relative;
                pointer-events: none;
                background-color: aqua;
                
    
                &::after{
                    top: -4px;
                }
    
                &::before{
                    top: 4px;
                }
            }
    
            .burgerActive{
                
                &::after{
                    transform: rotate(45deg);
                    top: -1px;
                }
    
                &::before{
                    transform: rotate(-45deg);
                    top: 1px;
                }
            }
        }
    }
}


@media only screen and (min-width: 600px) {
    .header{
        padding: 20px;

        .bar{
            font-size: 15px;
        }

        .shop{
            display: block;
            cursor: pointer;
        }
    }
}
@media only screen and (max-width: 600px) {
    .header{
        display: block;
        opacity: 1;
    }
}