.Img-container{
    position: relative;
    display: block;

}
.Img-container ::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 300px;
    background: linear-gradient(180deg, rgba(0,0,0,0),black);
}
.line-clamp-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-overflow: ellipsis;
  }
.subServices:hover {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  .inner-shadow-multiple {
    box-shadow: 
      inset 26.48px 26.43px 81px rgba(0, 0, 0, 0.55), /* First shadow */
      inset -26.43px -26.43px 66.06px rgba(59, 68, 81, 0.50), /* Second shadow */
      inset 2px 1px 2px rgba(255, 255, 255, 0.05); /* Third shadow */
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-4px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(6px, 0, 0);
    }
    
    30%, 50%, 70% {
      transform: translate3d(-6px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(6px, 0, 0);
  }
}