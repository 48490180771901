.lamp {
    content: "";
    display: block;
    position: absolute;
    width: 60px;
    top: 130px;
    left: 250px;

  }
  
  
  .lamp:after {
    content: "";
    display: block;
    position: absolute;
    width: 40px;
    height: 20px;
    top: -250px;
    left: -100px;
    border-top: 250px solid transparent;
    border-bottom: 300px solid rgba(137, 137, 137, 0.4);
    border-left: 180px solid transparent;
    border-right: 180px solid transparent;
    filter: blur(50px);
    z-index: 1;
  }
@keyframes swing {
    0% {
      transform: rotate(-20deg);
    }
    100% {
      transform: rotate(20deg);
    }
  }
  
  .swing {
    animation: swing 1.5s infinite alternate ease-in-out;
    transform-origin: 50% 50%;
  }