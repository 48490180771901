.nv:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    bottom: -5px;
    background: #fff;
    position: absolute;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  .nv:hover:after { 
    width: 100%; 
    left: 0; 
  }
  .nvw:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    bottom: -5px;
    background: #000;
    position: absolute;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  .nvw:hover:after { 
    width: 100%; 
    left: 0; 
  }
 