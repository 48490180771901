.body{
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;

    a{
        color: black;
        text-decoration: none;
        text-transform: uppercase;
    }

    p{
        margin: 5px;
        display: flex;
        overflow: hidden;
        font-size: 32px;
        padding-right: 30px;
        padding-top: 10px;
        font-weight: 400;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }
}

@media only screen and (min-width: 1000px) {
   
    .body{
        max-width: 1200px;
        margin-top: 80px;

        p{
            font-size: 5vw;
            padding-right: 2vw;
        }
    }
}
@media only screen and (max-width: 769px) {
   
    .body{
        flex-direction: column;

        p{
            font-size: 10vw;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            padding-right: 2vw;
            padding-left: 2vw;
        }
    }
}