@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/impact');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Londrina+Shadow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Koulen&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*, *::before , *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


body {
    min-height: 100vh;
    font-size: 16px;
    line-height: 1.3;
    overflow-x: hidden;

    /* font-family: 'Bebas Neue', cursive;
     */
}

.wrapper {
    width: 500%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
}

.logo {
    position: fixed;
    z-index: 10;
    top: 15%;
    left: 4rem;
    line-height: 0.9;
    color: white;
}

.line {
    background-color: white;
    height: 1rem;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
}

section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

section.character::before {
    content: '';
    width: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
}

.block {
    width: 50%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transform: translateX(-100%);
}

.what::before, .what .block {
    background-color: #ffffff;
}

.why::before, .why .block {
    background-color: #ffffff;
}
.who::before, .who .block {
    background-color: #ffffff;
}
.where::before, .where .block {
    background-color: #ffffff;
}
.caption {
    position: absolute;
    bottom: 4rem;
    left: 4rem;
    width: 30%;
    font-weight: 400;
    color: white;
    font-family: monospace;
    transform: translate(100%, 100%);
}

.character img {
    position: relative;
    z-index: 10;
    height: 90vh;
    width: auto;
    transform: translateY(-100%);
}

.huge-text {
    background: linear-gradient(90deg, #ffffff 50%,#0d1117 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    font-size: 25rem;
    width: 100%;
    text-align: center;
    
    position: absolute;
    top: 20%;
    left: 0;
    transform: translateY(100%);
    font-weight: 700;
}

.nickname {
    position: absolute;
    top: 4rem;
    right: 4rem;
    font-size: 3rem;
    text-align: right;
    transform: translateY(-200%);
}

.nickname span {
    display: block;
    font-size: 70%;
    color: #fff;
}

.quote {
    position: absolute;
    bottom: 4rem;
    right: 4rem;
    font-size: 3rem;
    width: 30%;
    text-align: right;
    transform: translateY(200%);
}

.quote::before, .quote::after {
    content: '';
    color: #fff;
}