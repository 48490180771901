.nav{
    overflow: hidden;
    height: 100vh;
 
    .wrapper{
         display: flex;
         gap: 10px;
         margin-bottom: 80px;
     }
 
     .container{
         display: flex;
         flex-direction: column;
         width: fit-content;
     }
 }
 
 @media only screen and (min-width: 1000px) {
     .nav{
         .wrapper{
             margin-bottom: 0px;
             
         }
     }
 }