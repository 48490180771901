    .container{
        height: 300vh;
        position: relative;

        .sticky{
            position: sticky;
            overflow: hidden;
            top: 0;
            @media (min-width : 769px) {
                height: 100vh;
                
            }
            @media (max-width : 768px) {
                
                height:40vh;
               
            }

            .el{
                width: 100%;
                height: 100%;
                top: 0;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                .imageContainer{
                    position: relative;
                    object-fit: cover;
                    img{
                        object-fit: cover;
                    }
                }
                @media (max-width: 768px) {
                    .imageContainer{
                        position: relative;
                        width: 33vw;
                        height: 10vh;
                        object-fit: cover;
                        img{
                            object-fit: cover;
                        }
                    }
                    &:nth-of-type(2){
                        .imageContainer{
                            top: -12vh;
                            left: 10vw;
                            width:50vw;
                            height: 12vh;
    
                        }
                    }
                    &:nth-of-type(3){
                        .imageContainer{
                            top: -5vh;
                            left: -32vw;
                            width: 28vw;
                            height: 20vh;
                        }
                    }
                    &:nth-of-type(4){
                        .imageContainer{
                            left: 32.5vw;
                            width: 28vw;
                            height: 10vh;
                        }
                    }
                    &:nth-of-type(5){
                        .imageContainer{
                            top: 11vh;
                            left: 10vw;
                            width: 26vw;
                            height: 10vh;
                        }
                    }
                    &:nth-of-type(6){
                        .imageContainer{
                            top: 11vh;
                            left: -25.8vw;
                            width: 40vw;
                            height: 10vh;
                        }
                    }
                    &:nth-of-type(7){
                        .imageContainer{
                            top: 11vh;
                            left: 32vw;
                            width: 14vw;
                            height: 8vh;
                        }
                    }
                }@media (min-width: 769px) {
                    .imageContainer{
                        position: relative;
                        width: 25vw;
                        height: 25vh;
                        img{
                            object-fit: cover;
                        }
                    }
                    &:nth-of-type(2){
                        .imageContainer{
                            top: -30vh;
                            left: 5vw;
                            width: 35vw;
                            height: 30vh;
        
                        }
                    }
                    &:nth-of-type(3){
                        .imageContainer{
                            top: -10vh;
                            left: -25vw;
                            width: 20vw;
                            height: 45vh;
                        }
                    }
                    &:nth-of-type(4){
                        .imageContainer{
                            left: 27.5vw;
                            width: 25vw;
                            height: 25vh;
                        }
                    }
                    &:nth-of-type(5){
                        .imageContainer{
                            top: 27.5vh;
                            left: 5vw;
                            width: 20vw;
                            height: 25vh;
                        }
                    }
                    &:nth-of-type(6){
                        .imageContainer{
                            top: 27.5vh;
                            left: -22.5vw;
                            width: 30vw;
                            height: 25vh;
                        }
                    }
                    &:nth-of-type(7){
                        .imageContainer{
                            top: 22.5vh;
                            left: 25vw;
                            width: 15vw;
                            height: 15vh;
                        }
                    }
                }

               
            }
        }
    }

